import css from "./Layout.module.scss";
import { Outlet } from "react-router-dom";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export const Layout = () => {
    return (
        <div className={css.layout__wrapper}>
            <div className={css.layout}>
                <Header />
                <Outlet />
                <Footer />
            </div>
        </div>
    );
};
