import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledLink = styled(NavLink)`
    &.active {
        border-bottom-color: #FEA55F;
        color: #FFFFFF;
    }

    &:hover:not(.active) {
        color: #FFFFFF;
    }
`;
