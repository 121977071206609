import css from "./Footer.module.scss";
import icons from "../../img/icons.svg";

export const Footer = () => {
    return (
        <footer className={css.footer}>
            <p className={css.footer__find}>find me in:</p>
            <nav className={css.footer__navigation}>
                <ul className={css.footer__list}>
                    <li className={css.footer__item}>
                        <a
                            href="https://www.instagram.com/oleksandrilnytskyi/"
                            target="blank"
                            className={css.footer__link}
                        >
                            <svg width={24} height={24}>
                                <use href={`${icons}#icon-instagram`} />
                            </svg>
                        </a>
                    </li>
                    <li className={css.footer__item}>
                        <a
                            href="https://www.linkedin.com/in/oleksandr-ilnytskyi241019/"
                            target="blank"
                            className={css.footer__link}
                        >
                            <svg width={24} height={24}>
                                <use href={`${icons}#icon-linkedin`} />
                            </svg>
                        </a>
                    </li>
                </ul>
                <div className={css.footer__item}>
                    <a
                        href="https://github.com/alex-main-academy"
                        target="blank"
                        className={`${css.footer__link} ${css.footer__github}`}
                    >
                        <span>@github</span>
                        <svg width={24} height={24}>
                            <use href={`${icons}#icon-github`} />
                        </svg>
                    </a>
                </div>
            </nav>
        </footer>
    );
};
