import css from "./Header.module.scss";
import icons from "../../img/icons.svg";
import { StyledLink } from "./Link.styled";
import { Menu } from "../Menu/Menu";
import { useState } from "react";

export const Header = () => {
    const [menu, setMenu] = useState(false);

    const handleToggleMenu = () => {
        setMenu(!menu);
    };

    return (
        <>
            <header className={css.header}>
                <p className={css.header__name}>oleksandr-ilnytskyi</p>
                <nav className={css.header__navigation}>
                    <ul className={css.header__list}>
                        <li className={css.header__item}>
                            <StyledLink to="/" className={css.header__link}>
                                _hello
                            </StyledLink>
                        </li>
                        <li className={css.header__item}>
                            <StyledLink to="about" className={css.header__link}>
                                _about-me
                            </StyledLink>
                        </li>
                        <li className={css.header__item}>
                            <StyledLink
                                to="projects"
                                className={css.header__link}
                            >
                                _projects
                            </StyledLink>
                        </li>
                    </ul>
                    <div className={css.header__item}>
                        <StyledLink
                            to="contact"
                            className={`${css.header__link} ${css.header__contact}`}
                        >
                            _contact-me
                        </StyledLink>
                    </div>
                </nav>
                <button
                    className={css.header__burger}
                    onClick={handleToggleMenu}
                >
                    <svg width={24} height={20}>
                        <use
                            href={`${icons}#${
                                menu ? "icon-close" : "icon-burger"
                            }`}
                        />
                    </svg>
                </button>
            </header>
            {menu && <Menu handleToggleMenu={handleToggleMenu} />}
        </>
    );
};
