import css from "./Hello.module.scss";
import image1 from "../../img/hello/img1.webp";
import imageBlueBg from "../../img/hello/blue-bg.png";
import imageGreenBg from "../../img/hello/green-bg.png";

export const Hello = () => {
    const imageArray = [
        {
            id: 1,
            opc: "0.1",
        },
        {
            id: 2,
            opc: "0.4",
        },
        {
            id: 3,
            opc: "1",
        },
        {
            id: 4,
            opc: "0.4",
        },
        {
            id: 5,
            opc: "0.1",
        },
    ];

    return (
        <section className={css.hello}>
            <div className={css.hello__container}>
                <img
                    src={imageBlueBg}
                    alt="front-end developer"
                    width={454}
                    className={css.hello__blue_bg}
                />
                <img
                    src={imageGreenBg}
                    alt="front-end developer"
                    width={454}
                    className={css.hello__green_bg}
                />
                <p className={css.hello__uptitle}>Hi all. I am</p>
                <h1 className={css.hello__title}>Oleksandr Ilnytskyi</h1>
                <p className={css.hello__subtitle}>&gt; Front-end developer</p>
                <p className={css.hello__comment}>
                    {`// you can also see it on my Github page`}
                </p>
                <p className={css.hello__link}>
                    <b>const </b>
                    <span>githubLink </span>
                    <i>= </i>
                    <a
                        href="https://github.com/alex-main-academy"
                        target="blank"
                    >
                        “https://github.com/example/url”
                    </a>
                </p>
                <ul className={css.hello__list}>
                    {imageArray.map(({ id, opc }) => {
                        return (
                            <li className={css.hello__item} key={id}>
                                <img
                                    src={image1}
                                    style={{ opacity: opc }}
                                    alt="front-end developer"
                                    className={css.hello__image}
                                    width={480}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </section>
    );
};
