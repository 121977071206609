import css from "./Menu.module.scss";
import { MenuStyledLink } from "./MenuLink.styled";

export const Menu = ({ handleToggleMenu }) => {
    return (
        <div className={css.menu}>
            <ul className={css.menu__list}>
                <li className={css.menu__item}>
                    <MenuStyledLink
                        to="/"
                        onClick={handleToggleMenu}
                        className={css.menu__link}
                    >
                        _hello
                    </MenuStyledLink>
                </li>
                <li className={css.menu__item}>
                    <MenuStyledLink
                        to="about"
                        onClick={handleToggleMenu}
                        className={css.menu__link}
                    >
                        _about-me
                    </MenuStyledLink>
                </li>
                <li className={css.menu__item}>
                    <MenuStyledLink
                        to="projects"
                        onClick={handleToggleMenu}
                        className={css.menu__link}
                    >
                        _projects
                    </MenuStyledLink>
                </li>
                <li className={css.menu__item}>
                    <MenuStyledLink
                        to="contact"
                        onClick={handleToggleMenu}
                        className={css.menu__link}
                    >
                        _contact-me
                    </MenuStyledLink>
                </li>
            </ul>
        </div>
    );
};
